import { useContext } from 'react'
import Menu from './Menu'
import { TaskAlt, Approval, AssignmentInd, Dangerous, FactCheck, Factory, Healing, People, Science, MonitorHeart, HealthAndSafety, OpenInNewRounded, Troubleshoot } from '@mui/icons-material'
import MenuItem from './MenuItem'
import MenuDivider from './MenuDivider'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import UserContext from '../auth/UserContext'
import HelpDialog from '../help/HelpDialog'
import AssessmentGuidelines from '../help/AssessmentGuidelines'
import RiskMatrix from '../help/RiskMatrix'
import { FeatureFlags } from '../config/config'

export default function SidebarMenu(props = {}) {
  window.scrollTo(0, 0)
  const roleDetails = useContext(RoleContext)
  const userDetails = useContext(UserContext)

  const isNorthstarBottler = () => {
    if (roleDetails.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP])) {
      return userDetails.userProfile.manufacturers?.some((m) => {
        return m.isNorthstar
      })
    }
    return false
  }

  return (
    <Menu>
      {roleDetails.hasAnyRole() &&
        <>
          <MenuItem href="/my-assessments" title="My Assessments" icon={<AssignmentInd />} />
          <MenuItem href="/corrective-action-plan/my-list" title="My Corrective Actions" icon={<Approval />} />
          <MenuItem href="/manufacturer/profile/list" title="Manufacturer Profiles" icon={<Factory />} />

          {FeatureFlags.authorization && roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
            <MenuItem href="/facility-authorizations/list" title="Facility Authorizations" icon={<TaskAlt />} />
          }

          <MenuItem href="/process-capability-submissions/list" title="Process Capability Data" icon={<Science />} />
        </>
      }

      {roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
        <MenuItem href="/manufacturer/production-volumes" title="Production Volume Reporting" icon={<Factory />} 
      />}

      {isNorthstarBottler() && 
        <MenuItem href="/manufacturer/production-volumes" title="Production Volume Reporting" icon={<Factory />}
      />}

      {FeatureFlags?.[764709] && roleDetails.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP]) && <MenuItem href="/incident-management/list" title="Incident Management" icon={<HealthAndSafety />} />}

      {roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
        <>
          {FeatureFlags?.[764709] && <MenuItem href="/incident-management/list" title="IM Logs" icon={<HealthAndSafety />} />}
          {FeatureFlags?.[1896027] && <MenuItem href="/complaint-trends/list" title="Complaint Trends" icon={<Troubleshoot />} />}
        </>
      }

      <MenuDivider />
      {roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
        <>
          {FeatureFlags.mfi && 
            <MenuItem href="/fitness-indexes" title="Fitness Indexes" icon={<MonitorHeart />} />
          }
          <MenuItem href="/assessments" title="Facility Assessments" icon={<FactCheck />} />
          <MenuItem href="/corrective-action-plan/list" title="Facility Corrective Action" icon={<Healing />} />
        </>
      }
      {roleDetails.hasAnyRole() &&
        <>
          <li>
            <HelpDialog linkText='Assessment Guidelines' startIcon={<OpenInNewRounded />}>
              <AssessmentGuidelines />
            </HelpDialog>
          </li>
          <li>
            <HelpDialog linkText='Risk Matrix' startIcon={<OpenInNewRounded />} supportZoom={true}>
              <RiskMatrix />
            </HelpDialog>
          </li>
        </>
      }
      {roleDetails.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
        <>
          <MenuDivider />
          <MenuItem href="/users/list/" title="User Management" icon={<People />} />
        </>
      }
      {roleDetails.hasRole([ROLES.ADMIN]) && <MenuItem href="/app/admin" title="Application Admin" icon={<Dangerous />} />}
    </Menu>
  )
}
