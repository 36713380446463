import { Route, Routes } from 'react-router-dom'
import LogoutPage from '../auth/LogoutPage'
import ListAssessmentsPage from '../assessments/ListAssessmentsPage'
import EditSelfAssessmentPage from '../assessments/EditSelfAssessmentPage'
import ListUsersPage from '../users/ListUsersPage'
import UserFormPage from '../users/UserFormPage'
import ListCAPsPage from '../cap/ListCAPsPage'
import CAPPage from '../cap/CAPPage'
import HomePage from '../home/HomePage'
import ListManufacturerProfilesPage from '../manufacturer/ListManufacturerProfilesPage'
import EditAssessmentPage from '../assessments/EditAssessmentPage'
import CreateAssessmentGroupPage from '../assessments/CreateAssessmentGroupPage'
import ListCPKSubmissionsPage from '../cpk/ListCPKSubmissionsPage'
import CPKSubmissionFormPage from '../cpk/CPKSubmissionFormPage'
import CPKSubmissionFormPageBottledWater from '../cpk/CPKSubmissionFormPageBottledWater'
import ListAuthorizationsPage from '../authorizations/ListAuthorizationsPage'
import NewAuthorizationPage from '../authorizations/NewAuthorizationPage'
import AuthorizationFilesPage from '../authorizations/AuthorizationFilesPage'
import AuthorizationFormPage from '../authorizations/AuthorizationFormPage'
import AuthorizationPreviewPage from '../authorizations/AuthorizationPreviewPage'
import AuthorizationHelpPage from '../authorizations/AuthorizationHelpPage'
import CPKHelpkPage from '../cpk/CPKHelpPage'
import { ROLES } from '../auth/role'
import RoleContext from '../auth/RoleContext'
import AdminPage from '../admin/AdminPage'
import EditAuthorizationSettingsPage from '../admin/EditAuthorizationSettingsPage'
import { Fragment } from 'react'
import AssessmentGroupPage from '../assessments/AssessmentGroupPage'
import CreateManufacturerProfilePage from '../manufacturer/CreateManufacturerProfilePage'
import EditManufacturerProfilePage from '../manufacturer/EditManufacturerProfilePage'
import MyAssessmentsPage from '../assessments/MyAssessmentsPage'
import MyCAPsPage from '../cap/MyCAPsPage'
import ListIMLogsPage from '../im-logs/ListIMLogsPage'
import IMLogFormPage from '../im-logs/IMLogFormPage'
import IMLogHelpPage from '../im-logs/IMLogHelpPage'
import { FeatureFlags } from '../config/config'
import ListProductionVolumesPage from '../manufacturer/ListProductionVolumesPage'
import ProductionVolumePage from '../manufacturer/ProductionVolumePage'
import ListComplaintTrendsPage from '../complaintTrends/ListComplaintTrendsPage'
import ListFitnessIndexesPage from '../fitnessIndexes/ListFitnessIndexesPage'
import FitnessIndexFormPage from '../fitnessIndexes/FitnessIndexFormPage'
import ComplaintTrendPage from '../complaintTrends/ComplaintTrendPage'

export default function Body(props = {}) {
  return (
    <RoleContext.Consumer>
      {roleCtx => (
        <div className="content-wrapper">
          <section className="content body">
            <div className="row">
              <Routes>
                <Route exact={false} path="/" element={<HomePage />} />
                <Route exact={true} path="/manufacturer/profile/list" element={<ListManufacturerProfilesPage />} />
                <Route exact={true} path="/manufacturer/profile/edit/:mfrProfileId" element={<EditManufacturerProfilePage />} />
                <Route exact={true} path="/manufacturer/production-volumes" element={<ListProductionVolumesPage />} />
                <Route exact={true} path="/manufacturer/production-volumes/:mfrProfileId" element={<ListProductionVolumesPage />} />
                <Route exact={true} path="/manufacturer/production-volume/:mfrProfileId/:volumeId" element={<ProductionVolumePage />} />

                {FeatureFlags.authorization && roleCtx.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
                  <>
                    <Route exact={true} path="/facility-authorizations/list" element={<ListAuthorizationsPage />} />
                    <Route exact={true} path="/facility-authorizations/help" element={<AuthorizationHelpPage />} />
                    <Route exact={true} path="/facility-authorizations/create" element={<NewAuthorizationPage />} />
                    <Route exact={true} path="/facility-authorizations/:authorizationId/files" element={<AuthorizationFilesPage />} />
                    <Route exact={true} path="/facility-authorizations/:authorizationId/edit" element={<AuthorizationFormPage editable={true} />} />
                    <Route exact={true} path="/facility-authorizations/:authorizationId" element={<AuthorizationFormPage editable={false} />} />
                    <Route exact={true} path="/facility-authorizations/:authorizationId/preview" element={<AuthorizationPreviewPage />} />
                  </>}

                {roleCtx.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
                  <>
                    <Route exact={true} path="/assessments/:mfrProfileId" element={<ListAssessmentsPage />} />
                    <Route exact={true} path="/assessments" element={<ListAssessmentsPage />} />
                    <Route exact={true} path="/manufacturer/profile/create" element={<CreateManufacturerProfilePage />} />
                    <Route exact={true} path="/assessments/create" element={<CreateAssessmentGroupPage />} />
                    <Route exact={true} path="/assessments/create/:mfrProfileId" element={<CreateAssessmentGroupPage />} />
                  </>}

                <Route exact={true} path="/my-assessments" element={<MyAssessmentsPage />} />
                <Route exact={true} path="/assessments/group/:assessmentId" element={<AssessmentGroupPage />} />
                <Route exact={true} path="/assessments/edit/self-assessments/:mfrProfileId/:selfAssessmentId" element={<EditSelfAssessmentPage />} />
                <Route exact={true} path="/assessments/edit/:type/:assessmentId" element={<EditAssessmentPage />} />

                {roleCtx.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP, ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
                  <Fragment>
                    <Route exact={true} path="/process-capability-submissions/help" element={<CPKHelpkPage />} />
                    <Route exact={true} path="/process-capability-submissions/list" element={<ListCPKSubmissionsPage />} />
                    <Route exact={true} path="/process-capability-submissions/create" element={FeatureFlags.cpkBottledWater ? <CPKSubmissionFormPageBottledWater /> : <CPKSubmissionFormPage />} />
                    <Route exact={true} path="/process-capability-submissions/view/:submissionId" element={FeatureFlags.cpkBottledWater ? <CPKSubmissionFormPageBottledWater /> : <CPKSubmissionFormPage />} />
                  </Fragment>
                }

                <Route exact={true} path="/logout" element={<LogoutPage />} />

                <Route exact={true} path="/corrective-action-plan/my-list" element={<MyCAPsPage />} />
                {roleCtx.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
                  <>
                    <Route exact={true} path="/corrective-action-plan/list" element={<ListCAPsPage />} />
                    <Route exact={true} path="/corrective-action-plan/list?o=:o" element={<ListCAPsPage />} />
                    <Route exact={true} path="/corrective-action-plan/list/:mfrProfileId" element={<ListCAPsPage />} />
                    {FeatureFlags.mfi && 
                      <>
                        <Route exact={true} path="/fitness-indexes" element={<ListFitnessIndexesPage />} />
                        <Route exact={true} path="/fitness-indexes/:fitnessIndexId" element={<FitnessIndexFormPage/>} />
                      </>
                    }
                  </>
                }
                <Route exact={true} path="/corrective-action-plan/:assessmentId/:capId" element={<CAPPage />} />

                <Route exact={true} path="/corrective-action-plan/my-list" element={<MyCAPsPage />} />
                <Route exact={true} path="/corrective-action-plan/list/:mfrProfileId" element={<ListCAPsPage />} />
                <Route exact={true} path="/corrective-action-plan/:assessmentId/:capId" element={<CAPPage />} />

                {roleCtx.hasRole([ROLES.BOTTLER, ROLES.BOTTLER_CORP, ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
                  <Fragment>
                    <Route exact={true} path="/incident-management/list" element={<ListIMLogsPage />} />
                    <Route exact={true} path="/incident-management/create" element={<IMLogFormPage />} />
                    <Route exact={true} path="/incident-management/help" element={<IMLogHelpPage />} />
                  </Fragment>
                }

                {roleCtx.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
                  <Fragment>
                    <Route exact={true} path="/incident-management/edit/:id" element={<IMLogFormPage />} />
                  </Fragment>
                }

                {roleCtx.hasRole([ROLES.QSE_MGR, ROLES.MFI_ADMIN, ROLES.ADMIN]) &&
                  <>
                    <Route exact={true} path="/users/list" element={<ListUsersPage />} />
                    <Route exact={true} path="/users/edit/:koId" element={<UserFormPage />} />

                    <Route exact={true} path="/complaint-trends/list" element={<ListComplaintTrendsPage />} />
                    <Route exact={true} path="/complaint-trends/:complaintTrendId" element={<ComplaintTrendPage />} />
                  </>
                }

                {/* <Route exact={true} path="/assessments/quality-and-food-safety-assessments/edit/:id" element ={ <QualityAndFSAssessmentFormPage /> } />
                <Route exact={true} path="/assessments/environmental-and-safety-assessments/edit/:id" element ={ <EnvironmentalAndSafetyAssessmentFormPage /> } /> */}
                {/* <Route exact={true} path="/assessments/self-assessments" element ={ <ListSelfAssessmentsPage /> } /> */}
                {/* <Route exact={true} path="/assessments/quality-and-food-safety-assessments" element ={ <ListQualityAndFSAssessmentsPage /> } /> */}
                {/* <Route exact={true} path="/assessments/environmental-and-safety-assessments" element ={ <ListEnvironmentalAndSafetyAssessmentsPage /> } /> */}

                {roleCtx.hasRole([ROLES.ADMIN]) && <>
                  <Route exact={true} path="/app/admin" element={<AdminPage />} />
                  <Route exact={true} path="/app/admin/edit-authorization-letter-template" element={<EditAuthorizationSettingsPage />} />
                </>
                }
              </Routes>
            </div>
          </section>
        </div>
      )}
    </RoleContext.Consumer>
  )
}
